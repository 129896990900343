import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";

import { ListItem } from "@mui/material";

const _nav = [
  {
    component: ListItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <GridViewIcon className="nav-icon" />,
    backend_flag: 2,
  },
  {
    component: ListItem,
    name: "Student",
    to: "/student",
    icon: <SchoolOutlinedIcon className="nav-icon" />,
    backend_flag: 2,
  },
  {
    component: ListItem,
    name: "Teacher",
    to: "/teacher",
    icon: <NoteAltOutlinedIcon className="nav-icon" />,
    backend_flag: 2,
  },
  {
    component: ListItem,
    name: "Upload Data",
    to: "/upload-data",
    icon: <UploadFileIcon className="nav-icon" />,
    backend_flag: 2,
  },
];

export default _nav;
