import React from "react";
import "./input.scss";

import { IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const Input = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <>
      {props.type === "string" ? (
        <TextField
          id={props.id}
          variant={props.variant}
          label={props.label}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={"input-string-component " + props.className}
          onChange={(e) => props.onChange(e.target.value)}
          defaultValue={props.defaultValue}
          style={props.style}
          value={props.value}
          required={props.required}
          size="small"
          autoComplete={props.autoComplete ? props.autoComplete : "off"}
          onClick={() => props.onClick && props.onClick()}
          onKeyDown={(event) => props.onKeyDown && props.onKeyDown(event)}
          InputLabelProps={{
            style: {
              padding: "0px 5px",
              top: "-2px",
            },
          }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {props.startAdornmentIcon}
              </InputAdornment>
            ),
          }}
        />
      ) : null}

      {props.type === "password" ? (
        <TextField
          id={props.id}
          label={props.label}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
          defaultValue={props.defaultValue}
          style={props.style}
          value={props.value}
          required={props.required}
          type={showPassword ? "string" : "password"}
          size="small"
          fullWidth
          autoComplete={props.autoComplete ? props.autoComplete : "off"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {props.startAdornmentIcon}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((show) => !show)}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon fontSize="small" />
                  ) : (
                    <VisibilityOffOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : null}
      {props.type === "select" ? (
        <TextField
          variant={props.variant}
          id={props.id}
          label={props.label}
          placeholder={props.placeholder}
          disabled={props.disabled}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
          defaultValue={props.defaultValue}
          style={props.style}
          value={props.value}
          required={props.required}
          size="small"
          InputLabelProps={{
            style: {
              padding: "0px 5px",
              top: "-2px",
            },
          }}
          fullWidth
          select
        >
          {props.menuItems?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ) : null}
    </>
  );
};

export default Input;
