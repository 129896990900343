import axios from "axios";

const API_BASE_URL = "https://kriahquiz.somee.com";

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// GET request function
export const getRequest = async (endpoint, config = {}) => {
  try {
    const response = await api.get(endpoint, config);
    return response.data;
  } catch (error) {
    console.error("Error during GET request:", error);
    throw error.response || error.message || error;
  }
};

// POST request function
export const postRequest = async (endpoint, data, config = {}) => {
  try {
    const response = await api.post(
      endpoint,
      new URLSearchParams(data).toString(),
      {
        ...config,
        headers: {
          ...config.headers,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error during POST request:", error);
    throw error.response || error.message || error;
  }
};

// PUT request function
export const putRequest = async (endpoint, data, config = {}) => {
  try {
    const response = await api.put(endpoint, data, config);
    return response.data;
  } catch (error) {
    console.error("Error during PUT request:", error);
    throw error.response || error.message || error;
  }
};

// DELETE request function
export const deleteRequest = async (endpoint, config = {}) => {
  try {
    const response = await api.delete(endpoint, config);
    return response.data;
  } catch (error) {
    console.error("Error during DELETE request:", error);
    throw error.response || error.message || error;
  }
};

// File upload function
export const uploadFile = async (endpoint, file, config = {}) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post(endpoint, formData, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during file upload:", error);
    throw error.response || error.message || error;
  }
};
