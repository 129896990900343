export const API = {
  signIn: "/NewLogScore.aspx",
  signUp: "/StudentRegister.aspx",
  AdminCsvData: "/AdminCsvDataAPI.aspx",
  GetQuizData: "/GetQuizData.aspx",
  TeacherCsvData: "/TeacherCsvDataAPI.aspx",
  QuizRecords: "/QuizRecords.aspx",
  StudentTrophies: "/StudentTrophies.aspx",
  StudentLog: "/StudentLog.aspx",
  AddQuizData: "/AddQuizData.aspx",
};
