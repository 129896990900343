import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../services/routes.service";
import AuthGuard from "../../services/authGuard";

const appContent = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, idx) => {
          const Component = route.component;
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              element={
                route.protected && (
                  <AuthGuard>
                    <Component />
                  </AuthGuard>
                )
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default appContent;
