import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Divider,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Tooltip } from "@mui/material";

const MyDialog = ({
  open,
  onClose,
  data,
  isStudent,
  trophies,
  editProfile,
}) => {
  // Using MUI useMediaQuery to detect screen size
  const isResponsive = useMediaQuery("(max-width:560px)");

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      {/* Conditional rendering based on screen size */}
      {!isResponsive ? (
        // Desktop view (Code 1)
        <DialogTitle sx={{ bgcolor: "#b7dbff", alignItems: "center" }}>
          <Stack flexDirection="row">
            <Grid container>
              <Grid item xs={2}>
                <Avatar
                  sx={{
                    width: 60,
                    height: 60,
                    border: "1px solid",
                    padding: "0.2rem",
                  }}
                  alt="Teacher Avatar"
                  src="https://cdn-icons-png.flaticon.com/512/2919/2919906.png"
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">Username: </strong>
                  <Tooltip title={data.Username}>
                    <span>
                      {data.Username.length > 8
                        ? `${data.Username.substr(0, 8)}...`
                        : data.Username}
                    </span>
                  </Tooltip>
                  {isStudent && (
                    <span onClick={editProfile}>
                      <EditOutlinedIcon fontSize="small" />
                    </span>
                  )}
                </Typography>
                <Typography
                  sx={{ display: "flex", gap: "45px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">Email: </strong>
                  <Tooltip title={data.Email}>
                    <span>
                      {data.Email.length > 10
                        ? `${data.Email.substr(0, 10)}...`
                        : data.Email}
                    </span>
                  </Tooltip>
                </Typography>
                <Typography
                  sx={{ display: "flex", gap: "12px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">Password: </strong>
                  <Tooltip title={data.PasswordHash}>
                    <span>
                      {data.PasswordHash.length > 10
                        ? `${data.PasswordHash.substr(0, 10)}...`
                        : data.PasswordHash}
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ display: "flex", gap: "24px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">Class: </strong>
                  <Tooltip title={data.Class}>
                    <span>
                      {data.Class.length > 10
                        ? `${data.Class.substr(0, 10)}...`
                        : data.Class}
                    </span>
                  </Tooltip>
                </Typography>
                <Typography
                  sx={{ display: "flex", gap: "12px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">School: </strong>
                  <Tooltip title={data.School}>
                    <span>
                      {data.School.length > 10
                        ? `${data.School.substr(0, 10)}...`
                        : data.School}
                    </span>
                  </Tooltip>
                </Typography>
                <Typography
                  sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <strong className="font-weight-600">Teacher: </strong>
                  <Tooltip title={data.Teacher}>
                    <span>
                      {data.Teacher.length > 10
                        ? `${data.Teacher.substr(0, 10)}...`
                        : data.Teacher}
                    </span>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <strong className="font-weight-600">ID</strong>#
                  {data.StudentId}
                </Typography>
              </Grid>
            </Grid>
            <IconButton sx={{ width: 40, height: 40 }} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
      ) : (
        // Responsive view (Code 2)
        <DialogTitle
          sx={{ bgcolor: "#b7dbff", padding: 2, position: "relative" }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              width: 40,
              height: 40,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            direction="column"
            spacing={2}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Avatar
              sx={{
                width: 60,
                height: 60,
                border: "1px solid",
                padding: "0.2rem",
              }}
              alt="Teacher Avatar"
              src="https://cdn-icons-png.flaticon.com/512/2919/2919906.png"
            />
            <Grid container spacing={1} sx={{ textAlign: "left" }}>
              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Username:</strong> {data.Username}
                  {isStudent && (
                    <span
                      onClick={editProfile}
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </span>
                  )}
                </Typography>
                <Typography variant="body2">
                  <strong>Email:</strong> {data.Email}
                </Typography>
                <Typography variant="body2">
                  <strong>Password:</strong> {data.PasswordHash}
                </Typography>
                <Typography variant="body2">
                  <strong>Class:</strong> {data.Class}
                </Typography>
                <Typography variant="body2">
                  <strong>School:</strong> {data.School}
                </Typography>
                <Typography variant="body2">
                  <strong>Teacher:</strong> {data.Teacher}
                </Typography>
                <Typography variant="body2">
                  <strong>ID:</strong> #{data.StudentId}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </DialogTitle>
      )}

      {/* Dialog content common for both views */}
      <DialogContent dividers>
        <Paper elevation={2} sx={{ padding: 2 }}>
          <div
            style={{ display: "flex", gap: "10px", flexDirection: "column" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">Completed Quiz</Typography>
                <Typography variant="body1">
                  {data.TotalQuizzesCompleted}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Questions Answered</Typography>
                <Typography variant="body1">
                  {data.TotalQuestionsAnswered}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Questions Correct</Typography>
                <Typography variant="body1">
                  {data.TotalQuestionsCorrect}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">Level</Typography>
                <Typography variant="body1">{data.Level}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">Game Tokens</Typography>
                <Typography variant="body1">{data.QuizTokenCount}</Typography>
              </Grid>
            </Grid>
          </div>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">Date Created</Typography>
              <Typography variant="body1">
                {new Date(data.DateCreated).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Last Updated</Typography>
              <Typography variant="body1">
                {new Date(data.LastUpdated).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <AccessTimeIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2">Quiz Accuracy</Typography>
            </Grid>
            <Grid item>
              <Typography variant={"h5"}>
                {data.TotalQuestionsCorrect > 0 &&
                data.TotalQuizzesCompleted > 0
                  ? (
                      (data.TotalQuestionsCorrect /
                        (data.TotalQuizzesCompleted * 10)) *
                      100
                    ).toFixed(2)
                  : "0"}
                %
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box
            display="flex"
            flexWrap="wrap"
            bgcolor="#eaeffa"
            height="120px"
            sx={{
              overflowY: "auto",
              padding: isResponsive ? "0.4rem" : "0.6rem",
              gap: isResponsive ? "7px" : "12px",
            }}
          >
            {trophies?.map((item) => (
              <img
                key={item.id}
                src={item.trophy}
                alt="Trophy"
                style={{
                  width: isResponsive ? "62px" : "70px",
                  height: isResponsive ? "62px" : "70px",
                  objectFit: "contain", // Maintain aspect ratio
                }}
              />
            ))}
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default MyDialog;
