import { Typography } from "@mui/material";
import React from "react";

function ValidationMessage({ error }) {
  return (
    <Typography
      className="error-message"
      sx={{
        fontSize: "11px",
        marginTop: "-14px",
        marginBottom: "-14px",
        color: "red",
      }}
    >
      {error}
    </Typography>
  );
}

export default ValidationMessage;
