import React, { useState } from "react";
import "./AuthPage.scss";

import SignIn from "../../components/signIn/signIn";
import SignUp from "../../components/signUp/signUp";
import loginImage from "../../assets/comp.gif";
import CustomLoader from "../../components/customLoader/CustomLoader";

const AuthPage = () => {
  const [isSignUpForm, SetIsSignUpFrom] = useState(false);
  const [loading, setLoading] = useState(false);
  const HandleSignUp = () => {
    SetIsSignUpFrom(true);
  };

  const HandleSignIn = () => {
    SetIsSignUpFrom(false);
  };

  return (
    <div className="login-component">
      <div className="left-section">
        <div className="kriah-title">Kriah Quiz</div>
        <img className="img" src={loginImage} alt="loginImage" />
      </div>
      <div className="right-section">
        {isSignUpForm === false ? (
          <SignIn signUpButton={HandleSignUp} setLoading={setLoading} />
        ) : (
          <SignUp SignInButton={HandleSignIn} setLoading={setLoading} />
        )}
      </div>
      {loading && <CustomLoader />}
    </div>
  );
};

export default AuthPage;
