import React from "react";

const Dashboard = React.lazy(() => import("../page/DashboardPage/Dashboard"));
const Student = React.lazy(() => import("../page/StudentPage/Student"));
const Quiz = React.lazy(() => import("../page/quiz/quiz"));
const Teacher = React.lazy(() => import("../page/teacherPage/teacherPage"));
const Upload = React.lazy(() => import("../page/uploadPage/uploadPage"));

const routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: true,
    protected: true,
  },
  {
    path: "student",
    name: "Student",
    component: Student,
    exact: true,
    protected: true,
  },
  {
    path: "teacher",
    name: "Teacher",
    component: Teacher,
    exact: true,
    protected: true,
  },
  {
    path: "upload-data",
    name: "Upload",
    component: Upload,
    exact: true,
    protected: true,
  },
  {
    path: "quiz",
    name: "Quiz",
    component: Quiz,
    exact: true,
    protected: true,
  },
];

export default routes;
