import ace from "../assets/trophies/ace.svg";
import apprentice from "../assets/trophies/apprentice.svg";
import champion from "../assets/trophies/champion.svg";
import expert from "../assets/trophies/expert.svg";
import genius from "../assets/trophies/genius.svg";
import hero from "../assets/trophies/hero.svg";
import legend from "../assets/trophies/legend.svg";
import master from "../assets/trophies/master.svg";
import novice from "../assets/trophies/novice.svg";
import phenom from "../assets/trophies/phenom.svg";
import scholar from "../assets/trophies/scholar.svg";
import streakChampion from "../assets/trophies/streak champion.svg";
import streakMaster from "../assets/trophies/streak master.svg";
import streakStarter from "../assets/trophies/streak starter.svg";
import prodigy from "../assets/trophies/prodigy.svg";
import gif1 from "../assets/gif/1.gif";
import gif2 from "../assets/gif/2.gif";
import gif3 from "../assets/gif/3.gif";
import gif4 from "../assets/gif/4.gif";
import gif5 from "../assets/gif/5.gif";
import gif6 from "../assets/gif/6.gif";
import gif7 from "../assets/gif/7.gif";
import gif8 from "../assets/gif/8.gif";
import gif9 from "../assets/gif/9.gif";
import gif10 from "../assets/gif/10.gif";
import gif11 from "../assets/gif/11.gif";
import gif12 from "../assets/gif/12.gif";
import gif13 from "../assets/gif/13.gif";
import gif14 from "../assets/gif/14.gif";
import gif15 from "../assets/gif/15.gif";
import gif16 from "../assets/gif/16.gif";
import gif17 from "../assets/gif/17.gif";
import gif18 from "../assets/gif/18.gif";
import gif19 from "../assets/gif/19.gif";
import gif20 from "../assets/gif/20.gif";
import gif21 from "../assets/gif/21.gif";

export const studentTableHeader = [
  {
    label: "Id",
    value: "StudentId",
    type: "string",
  },
  {
    label: "Username",
    value: "Username",
    type: "string",
  },
  {
    label: "Email",
    value: "Email",
    type: "string",
  },
  {
    label: "Teacher",
    value: "Teacher",
    type: "string",
  },
  {
    label: "School",
    value: "School",
    type: "string",
  },
  {
    label: "Class",
    value: "Class",
    type: "string",
  },
  {
    label: "Level",
    value: "Level",
    type: "string",
  },
  {
    label: "Last Quiz 1",
    value: "LastQuizRecord1",
    type: "string",
    width: "100px",
  },
  {
    label: "Last Quiz 2",
    value: "LastQuizRecord2",
    type: "string",
    width: "100px",
  },
  {
    label: "Last Quiz 3",
    value: "LastQuizRecord3",
    type: "string",
    width: "100px",
  },
  {
    label: "Last Quiz 4",
    value: "LastQuizRecord4",
    type: "string",
    width: "100px",
  },
  {
    value: "star",
    label: "Actions",
    type: "action",
    actions: [
      { value: "edit", label: "Edit" },
      { value: "delete", label: "Delete" },
      { value: "view_all", label: "View All" },
    ],
  },
];

export const menuItemSchool = [
  {
    label: "Id",
    value: "StudentId",
  },
  {
    label: "Username",
    value: "Username",
  },
  {
    label: "Email",
    value: "Email",
  },
];

export const teacherTableHeader = [
  {
    label: "Id",
    value: "StudentId",
    type: "string",
  },
  {
    label: "Username",
    value: "Username",
    type: "string",
  },
  {
    label: "Email",
    value: "Email",
    type: "string",
  },
  {
    label: "School",
    value: "School",
    type: "string",
  },
  {
    label: "Class",
    value: "Class",
    type: "string",
  },
  {
    value: "star",
    label: "Actions",
    type: "action",
    actions: [
      { value: "edit", label: "Edit" },
      { value: "delete", label: "Delete" },
      { value: "view_all", label: "View All" },
    ],
  },
];

export const levelOptionList = [
  {
    label: 1.1,
    value: 1.1,
  },
  {
    label: 1.2,
    value: 1.2,
  },
  {
    label: 1.3,
    value: 1.3,
  },
  {
    label: 1.4,
    value: 1.4,
  },
  {
    label: 2.1,
    value: 2.1,
  },
  {
    label: 2.2,
    value: 2.2,
  },
  {
    label: 2.3,
    value: 2.3,
  },
  {
    label: 3.1,
    value: 3.1,
  },
  {
    label: 3.2,
    value: 3.2,
  },
  {
    label: 3.3,
    value: 3.3,
  },
  {
    label: 3.4,
    value: 3.4,
  },
  {
    label: 4.1,
    value: 4.1,
  },
  {
    label: 4.2,
    value: 4.2,
  },
  {
    label: 4.4,
    value: 4.4,
  },
  {
    label: 4.5,
    value: 4.5,
  },
  {
    label: 5.2,
    value: 5.2,
  },
  {
    label: 5.3,
    value: 5.3,
  },
  {
    label: 6.1,
    value: 6.1,
  },
  {
    label: 7.1,
    value: 7.1,
  },
  {
    label: 8.1,
    value: 8.1,
  },
];

export const trophiesLists = [
  {
    trophy: novice,
    id: 1,
  },
  {
    trophy: apprentice,
    id: 2,
  },
  {
    trophy: scholar,
    id: 3,
  },
  {
    trophy: prodigy,
    id: 4,
  },
  {
    trophy: ace,
    id: 5,
  },
  {
    trophy: genius,
    id: 6,
  },
  {
    trophy: legend,
    id: 7,
  },
  {
    trophy: expert,
    id: 8,
  },
  {
    trophy: master,
    id: 9,
  },
  {
    trophy: champion,
    id: 10,
  },
  {
    trophy: hero,
    id: 11,
  },
  {
    trophy: streakStarter,
    id: 12,
  },
  {
    trophy: streakChampion,
    id: 13,
  },
  {
    trophy: streakMaster,
    id: 14,
  },
  {
    trophy: phenom,
    id: 15,
  },
];

export const quizLevel = [
  {
    label: 1.1,
  },
  {
    label: 1.2,
  },
  {
    label: 1.3,
  },
  {
    label: 1.4,
  },
  {
    label: 2.1,
  },
  {
    label: 2.2,
  },
  {
    label: 2.3,
  },
  {
    label: 3.1,
  },
  {
    label: 3.2,
  },
  {
    label: 3.3,
  },
  {
    label: 3.4,
  },
  {
    label: 4.1,
  },
  {
    label: 4.2,
  },
  {
    label: 4.4,
  },
  {
    label: 4.5,
  },
  {
    label: 5.2,
  },
  {
    label: 5.3,
  },
  {
    label: 6.1,
  },
  {
    label: 7.1,
  },
  {
    label: 8.1,
  },
];

export const gifs = [
  {
    id: 1,
    gif: gif1,
  },
  {
    id: 2,
    gif: gif2,
  },
  {
    id: 3,
    gif: gif3,
  },
  {
    id: 4,
    gif: gif4,
  },
  {
    id: 5,
    gif: gif5,
  },
  {
    id: 6,
    gif: gif6,
  },
  {
    id: 7,
    gif: gif7,
  },
  {
    id: 8,
    gif: gif8,
  },
  {
    id: 9,
    gif: gif9,
  },
  {
    id: 10,
    gif: gif10,
  },
  {
    id: 11,
    gif: gif11,
  },
  {
    id: 12,
    gif: gif12,
  },
  {
    id: 13,
    gif: gif13,
  },
  {
    id: 14,
    gif: gif14,
  },
  {
    id: 15,
    gif: gif15,
  },
  {
    id: 16,
    gif: gif16,
  },
  {
    id: 17,
    gif: gif17,
  },
  {
    id: 18,
    gif: gif18,
  },
  {
    id: 19,
    gif: gif19,
  },
  {
    id: 20,
    gif: gif20,
  },
  {
    id: 21,
    gif: gif21,
  },
];
