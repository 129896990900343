import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import _nav from "./_nav";
import { getLocalStorage, setLocalStorage } from "../../services/utli.services";
import { styled } from "@mui/material/styles";

const drawerWidth = 250;

const StyledListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.action.selected,
    "&:hover": {
      backgroundColor: theme.palette.action.selected,
    },
  },
}));

const AppSidebar = ({ open, toggleDrawer, setDrawerOpen }) => {
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const userData = getLocalStorage("loginUserData");
    if (userData) {
      setLoginData(userData);
    }

    const savedIndex = getLocalStorage("selectedTabIndex");
    if (savedIndex !== null) {
      setSelectedIndex(parseInt(savedIndex, 10));
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 830) {
        setDrawerOpen(true);
      } else {
        setDrawerOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setDrawerOpen]);

  const handleNavigation = (path, index) => {
    setSelectedIndex(index);
    setLocalStorage("selectedTabIndex", index);
    navigate(`/home${path}`);
    toggleDrawer(false);
  };

  const getFilteredNavItems = () => {
    return _nav.filter(
      (item) => !(item.name === "Teacher" && loginData.UserType === 1)
    );
  };

  const drawerList = (
    <Box
      sx={{ width: drawerWidth }}
      role="presentation"
      onClick={toggleDrawer(false)}
      style={{ paddingTop: "4rem" }}
    >
      <List>
        {getFilteredNavItems().map((item, index) => (
          <StyledListItem
            button
            key={index}
            onClick={() => handleNavigation(item.to, index)}
            selected={selectedIndex === index}
          >
            {item.icon}
            <ListItemText primary={item.name} style={{ paddingLeft: "8px" }} />
          </StyledListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      variant={window.innerWidth < 830 ? "temporary" : "permanent"}
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        display: { xs: "block", sm: "s" },
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          color: "#3c3c3c",
        },
      }}
    >
      {drawerList}
    </Drawer>
  );
};

export default AppSidebar;
