import React from "react";
import { Navigate } from "react-router-dom";
const isAuthenticated = () => {
  return !!localStorage.getItem("loginUserData");
};

const AuthGuard = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default AuthGuard;
