import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthPage from "./page/AuthPage/AuthPage";
import Layout from "./components/containers/appLayout";
import AuthGuard from "./services/authGuard";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<AuthPage />} />
        <Route
          path="/home/*"
          element={
            <AuthGuard>
              <Layout />
            </AuthGuard>
          }
        />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
