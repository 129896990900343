import React, { useEffect, useState } from "react";
import "../../style/style.scss";
import { AppContent, AppHeader } from "./index";
import { getLocalStorage } from "../../services/utli.services";

const Layout = () => {
  const [studentLogin, setStudentLogin] = useState();

  useEffect(() => {
    const studentData = getLocalStorage("loginUserData");
    setStudentLogin(studentData);
  }, []);
  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div
          className={
            studentLogin?.UserType === 0 ? "studentDashBoard" : "app-content"
          }
        >
          <AppContent />
        </div>
      </div>
    </div>
  );
};

export default Layout;
