import * as React from "react";
import "./model.scss";
import Button from "../button/button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography, DialogActions } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    maxWidth: "400px", // Set max width here
    width: "100%", // Optional: Make the dialog take full width of its container
  },
}));

export default function Model({
  open,
  handleClose,
  title,
  children,
  handleButton,
  btnLabel,
  confirmationString,
  nobutton,
  exitButton,
  handleExit,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
          <IconButton
            aria-label="close"
            className="close-icon"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          {children}
          {confirmationString && (
            <Typography gutterBottom>{confirmationString}</Typography>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "center", padding: "0 1rem 2rem 1rem" }}
        >
          <Box sx={{ gap: "1rem" }} className="save-button">
            {nobutton && (
              <Button autoFocus onClick={handleClose} buttonType={"orange"}>
                {nobutton}
              </Button>
            )}
            {btnLabel ? (
              <Button autoFocus onClick={handleButton} buttonType={"blue"}>
                {btnLabel}
              </Button>
            ) : (
              ""
            )}
            {exitButton && (
              <Button autoFocus onClick={handleExit} buttonType={"orange"}>
                {exitButton}
              </Button>
            )}
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
